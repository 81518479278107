import authService from '../../../../services/auth'
import { AUTH_MUTATIONS } from '../../../../store/mutations'
import { Auth } from 'aws-amplify'
import { AmplifyEventBus } from 'aws-amplify-vue'
import Vue from "vue";
import VueCountryCode from "vue-country-code-select";
Vue.use(VueCountryCode);
export default {
    data() {
        return {
            dataPhoto: '',
            countryCodeName: '',
            originalCountryCode: '',
            cntOnload:0,
            user: {
                fname: '',
                lname: '',
                email: '',
                title: '',                
                companyname: '',
                phoneno: ''
            }
        }
    },
    computed: {

    },
    mounted() {
        this.fetchProfile()
    },
    methods: {
        onSelect({name, iso2, dialCode}) {
            debugger
            if(this.cntOnload == 0 || this.cntOnload > 1)
            {
                this.countryCodeName = dialCode;
            }
            else
            {
                 dialCode = this.countryCodeName;
            }
            this.cntOnload++;
          },
        fetchProfile() {
                var storedNames = JSON.parse(localStorage.getItem("userD"));
                this.user.fname = storedNames[1];
                this.user.lname = storedNames[2];
                this.user.email = storedNames[3];
                this.user.title = storedNames[4];
                this.user.companyname = storedNames[5];
                //debugger
                if(storedNames[6] != "" && storedNames[6].length > 10) {
                    var Len = storedNames[6].length;
                    this.originalCountryCode = storedNames[6].substr(1,(Len - 11));
                    this.countryCodeName = storedNames[6].substr(0,(Len - 10));
                    this.user.phoneno = storedNames[6].substr((Len - 10),Len);
                }       
                else    
                {
                    this.originalCountryCode = '+91';
                    this.countryCodeName = "+91";
                    this.user.phoneno = storedNames[6];
                }
        },
        renderFileImg() {
        },
        async update() {
            await this.updateUser();
        },
        async updateUser() {
            debugger
            const user = await Auth.currentAuthenticatedUser();
            await Auth.updateUserAttributes(user, {
              'custom:companyname': this.user.companyname, 
              'custom:firstname': this.user.fname,
            'custom:lastname': this.user.lname,
            'custom:title': this.user.title,
              phone_number: (this.user.phoneno != "" && this.user.phoneno != "" ? "+"  + this.countryCodeName + this.user.phoneno: null )
            })
            .then(data =>{
                var userD = [];
                userD[0] = this.user.fname + " " + this.user.lname;
                userD[1] = this.user.fname;
                userD[2] = this.user.lname;
                userD[3] = this.user.email;
                userD[4] = this.user.title;
                userD[5] = this.user.companyname;
                userD[6] = (this.user.phoneno != "" && this.user.phoneno != "" ? "+"  + this.countryCodeName + this.user.phoneno: null );
                localStorage.setItem("userD", JSON.stringify(userD));
                localStorage.setItem('storedData', this.user.fname + " " + this.user.lname)
                this.$store.commit(AUTH_MUTATIONS.UPDATE_USER_PROFILE, this.user)
                this.$router.push({name: 'Account'})
                //console.log(data1)
              })
              .catch(err =>{
                //this._showToast(err.message, { variant: 'warning' })
                //console.log(err)
              });
          },
        cancel() {
            this.$router.push({name: 'Account'})
        }
    }
}